html {
    scroll-snap-type: y mandatory;
  }
  
  .app {
    height: 100vh;
    width: 100vw;
    background-color: black;
    display: grid;
    place-items: center;
  }
  
  .app__videos {
    position: relative;
    height: 90vh;
    width: 100%;
    max-width: 450px;
    overflow: scroll;
    scroll-snap-type: y mandatory;
  }
  
  .app__videos::-webkit-scrollbar {
    display: none;
  }
  /* for IE Safari*/
  .app__videos {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  